import React from 'react'
import HomePageBorder from "./../components/home-page-border";
import Layout from '../components/layout'
import Logo from "../components/logo";

const IndexPage = () => (
  <Layout hero={<Logo />}>
    <HomePageBorder />
  </Layout>
)

export default IndexPage
