import React from "react";
import Link from 'gatsby-link'
import "./home-page-border.scss";

export default () => (
  <div className="home-page-border">
      <div className="date">
        <div className="first">Friday</div>
        <div>25 Oct 2019</div>
        <div>7:30 PM</div>
      </div>
      <div className="place">
        The Tigermen Den
      </div>
      <div className="description-container first">
        <div className="description">
          Welcome Reception
        </div>
      </div>
      <div className="date">
        <div className="first">Saturday</div>
        <div>26 Oct 2019</div>
        <div>5:00 PM</div>
      </div>
      <div className="place">
        New Orleans Pharmacy Museum
      </div>
      <div className="description-container">
        <div className="description">
          Ceremony
        </div>
      </div>
      <div className="place">
        Napoleon House
      </div>
      <div className="description-container">
        <div className="description">
          Reception
        </div>
      </div>
      <div className="button-container">
      <Link to="/wedding-details/">
        <div className="button">
            more info
        </div>
      </Link>
      <Link to="/rsvp/">
        <div className="button">
          rsvp
        </div>
      </Link>
      </div>
  </div>
)
